<template>
  <div>
    <!-- START OF GRADIENT JUMBOTRON -->
    <section class="gradient-jumbotron">
      <div>
        <h1>About Us</h1>
      </div>
    </section>
    <!-- END OF GRADIENT JUMBOTRON -->

    <!-- START OF SUBPAGE CONTENT -->
    <div class="subpage-content">
      <div class="container">
        <div class="aboutus-description">
          <h2>About Web8</h2>
          <p>
            We are a SAAS product and solution provider established in 2024 and
            located in Taguig City. We focus on providing innovative SAAS
            products and customized solutions to customers in target industries
            such as gaming and retail. We guide our clients through the software
            development life cycle. The founding team is from Singapore and has
            15 years of experience in the SAAS industry.
          </p>

          <p>
            Our team is led by industry veterans and technologists, with vast
            and relevant experience to tap into for your unique challenges.
          </p>

          <!-- START OF ABOUT US SUB CONTENT - TWO IN A ROW -->
          <div class="row row1">
            <div class="aboutus-subcontent">
              <img
                src="../assets/images/icon-information-latest.png"
                class="icon"
              />
              <h2>Founded In</h2>
              <p>2024</p>
              <p>Taguig City</p>
            </div>

            <div v-show="false" class="col-md-8">
              <div class="aboutus-subcontent">
                <img src="../assets/images/icon-services.png" class="icon" />
                <h2>Services offered</h2>
                <p></p>
                <p>Web Applications</p>
                <p>User Interface Design</p>
                <p>iOS & Android development</p>

                <!-- FIND OUT MORE BUTTON -->
                <div class="more">
                  <a @click="handleClicBtn('ourSolutions')">
                    <div class="more-btn">
                      Find Out More
                      <i class="bi bi-chevron-right" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF ABOUT US SUB CONTENT - TWO IN A ROW -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleClicBtn(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style>
.row1 {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
</style>
