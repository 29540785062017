<template>
  <div>
    <section class="section" style="padding-bottom: 0">
      <!-- START OF MAIN BANNER -->
      <div class="container">
        <section class="banner">
          <div class="container">
            <div class="banner-img">
              <img
                alt="Banner"
                class="img-fluid"
                src="../assets/images/mainbanner.png"
              />
            </div>

            <div class="banner-text animate__animated animate__fadeInLeft">
              <h1>
                We provide<br />
                <span>solutions</span><br />
                for your business
              </h1>

              <div>
                <a class="btn" href="http://crm.wdmt.net">Try it now</a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- END OF MAIN BANNER -->
    </section>
    <!-- START OF WHY CHOOSE Web8 - TWO ROWS -->
    <section class="whychoose-content">
      <div class="container">
        <div class="col-md-12">
          <h1>Why Choose Web8</h1>
          <!-- START OF FIRST ROW -->

          <div class="row">
            <!-- METHODOLOGIES -->
            <div class="col-md-4">
              <div class="card">
                <img
                  alt="Methodologies"
                  class="icon"
                  src="../assets/images/icon-methodologies.png"
                />
                <h2>Innovative Solutions</h2>

                <p>
                  Our SaaS products offer innovative solutions tailored to the
                  needs of small and medium-sized local stores, providing them
                  with cutting-edge technology to stay competitive in their
                  markets.
                </p>
              </div>
            </div>
            <!-- EXPERIENCE -->

            <div class="col-md-4">
              <div class="card">
                <img
                  alt="Experience"
                  class="icon"
                  src="../assets/images/icon-experience.png"
                />
                <h2>Customized Support</h2>

                <p>
                  We provide customized customer service solutions, ensuring
                  that each client receives personalized support throughout
                  their journey, from implementation to ongoing maintenance.
                </p>
              </div>
            </div>
            <!-- CONNECT WITH US -->

            <div class="col-md-4">
              <div class="card">
                <img
                  alt="Connect With Us"
                  class="icon"
                  src="../assets/images/icon-connect-with-us.png"
                />
                <h2>Guidance Through Development</h2>

                <p>
                  Our team guides clients through the software development life
                  cycle, offering expert advice and support at every stage of
                  the process, from ideation to deployment.
                </p>
              </div>
            </div>
          </div>
          <!-- END OF FIRST ROW --><!-- START OF SECOND ROW -->

          <div class="row second">
            <!-- ON-TIME ASSISTANCE -->
            <div class="col-md-4">
              <div class="card">
                <img
                  alt="On-time Assistance"
                  class="icon"
                  src="../assets/images/icon-on-time-assistance.png"
                />
                <h2>Proven Experience</h2>

                <p>
                  With a founding team boasting 15 years of experience in the
                  SaaS industry, customers can trust in our expertise and track
                  record of success in delivering high-quality solutions.
                </p>
              </div>
            </div>
            <!-- ON-TIME DELIVERY -->

            <div class="col-md-4">
              <div class="card">
                <img
                  alt="On-time Delivery"
                  class="icon"
                  src="../assets/images/icon-on-time-delivery.png"
                />
                <h2>Focus on Customer Success</h2>

                <p>
                  Our primary focus is on the success of our customers. We are
                  dedicated to helping them achieve their goals and providing
                  them with the tools and support they need to thrive in their
                  respective industries.
                </p>
              </div>
            </div>
            <!-- END OF SECOND ROW -->
          </div>
        </div>
      </div>
    </section>
    <!-- END OF WHY CHOOSE Web8 - TWO ROWS --><!-- START OF ABOUT Web8 -->

    <section class="aboutus-content">
      <div class="container">
        <h1>About Web8</h1>

        <p>
          We are a SAAS product and solution provider established in 2024 and
          located in Taguig City. We focus on providing innovative SAAS products
          and customized solutions to customers in target industries such as
          gaming and retail. We guide our clients through the software
          development life cycle. The founding team is from Singapore and has 15
          years of experience in the SAAS industry.
        </p>
        <!-- START OF BTN CONTAINER -->

        <div class="btn-container">
          <div class="btn">
            <a @click="handleClicBtn('aboutUs')">Find Out More </a>
          </div>
        </div>
        <!-- END OF BTN CONTAINER -->
      </div>
    </section>
    <!-- END OF ABOUT Web8 --><!-- START OF FOOTER -->
  </div>
</template>

<script>
import goldline from "@/assets/images/goldline.png";
import aven from "@/assets/images/aven.png";
import kanba from "@/assets/images/kanba.png";
import kyan from "@/assets/images/kyan.png";
import spotify from "@/assets/images/spotify.png";
import Surface from "@/assets/images/Surface.png";
import dashboard from "@/assets/images/Dashboard.png";
import Image39 from "@/assets/images/Image-39.png";
export default {
  name: "Home",
  components: {
    // CarouselCopy,
  },
  data() {
    return {
      goldline,
      aven,
      kanba,
      kyan,
      spotify,
      Surface,
      dashboard,
      Image39,
      height: "100%",
    };
  },
  mounted() {
    // window.addEventListener("resize", this.changeHeight);
    // this.changeHeight();
  },
  methods: {
    changeHeight() {
      this.height = `${this.$refs.dashboardImg.offsetHeight + 80}px`;
    },
    handleClicBtn(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
